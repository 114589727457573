let commons = require('../utils/commons');

function initPricesDropdown(pricesNode) {
    const pricesDropdwonElements = pricesNode.getElementsByClassName('price-element');
    if (pricesDropdwonElements.length <= 0) {
        console.error('initPricesDropdown: no pricesDropdwonElements found by class "price-element"');
        return false;
    }
    let closeAll = function () {
        for(let i = 0; i < pricesDropdwonElements.length; i++) {
            let pricesDropdwonElement = pricesDropdwonElements.item(i);
            commons.removeClassName(pricesDropdwonElement, 'open');
        }
    }
    let toggle = function(element) {
        if (commons.hasClassName(element, 'open')) {
            commons.removeClassName(element, 'open');
        } else {
            commons.addClassName(element, 'open');
        }
    }
    for(let i = 0; i < pricesDropdwonElements.length; i++) {
       let pricesDropdwonElement = pricesDropdwonElements.item(i);
       let linkOpen = pricesDropdwonElement.getElementsByClassName('list-opener')[0];
       if (linkOpen === undefined || !linkOpen) {
           console.error('initPricesDropdown: linkOpen not found by class "list-opener" in ', pricesDropdwonElement);
           return false;
       }
       linkOpen.addEventListener('click', function(e){
           e.preventDefault();
           toggle(pricesDropdwonElement);
       });
    }
}

document.addEventListener('DOMContentLoaded', function(){
    const pricesContainers = document.getElementsByClassName('prices-list');
    for(let i = 0; i < pricesContainers.length; i++) {
        let pricesContainer = pricesContainers.item(i);
        initPricesDropdown(pricesContainer);
    }
})
