let commons = require('../utils/commons');

function initSpecialistsDropdown(specialistsNode) {
    const specialistsDropdwonElements = specialistsNode.getElementsByClassName('specialts-type-list');
    if (specialistsDropdwonElements.length <= 0) {
        console.error('initSpecialistsDropdown: no specialistsDropdwonElements found by class "specialts-type-list"');
        return false;
    }
    let closeAll = function () {
        for(let i = 0; i < specialistsDropdwonElements.length; i++) {
            let specialistsDropdwonElement = specialistsDropdwonElements.item(i);
            commons.removeClassName(specialistsDropdwonElement, 'open');
        }
    }
    let toggle = function(element) {
        if (commons.hasClassName(element, 'open')) {
            commons.removeClassName(element, 'open');
        } else {
            commons.addClassName(element, 'open');
        }
    }
    for(let i = 0; i < specialistsDropdwonElements.length; i++) {
       let specialistsDropdwonElement = specialistsDropdwonElements.item(i);
       let linkOpen = specialistsDropdwonElement.getElementsByClassName('list-opener')[0];
       if (linkOpen === undefined || !linkOpen) {
           console.error('initSpecialistsDropdown: linkOpen not found by class "list-opener" in ', specialistsDropdwonElement);
           return false;
       }
       linkOpen.addEventListener('click', function(e){
           e.preventDefault();
           toggle(specialistsDropdwonElement);
       });
    }
}

document.addEventListener('DOMContentLoaded', function(){
    const specialistsContainers = document.getElementsByClassName('specialists-list');
    for(let i = 0; i < specialistsContainers.length; i++) {
        let specialistsContainer = specialistsContainers.item(i);
        initSpecialistsDropdown(specialistsContainer);
    }
})
