function actionSlider(sliderNode) {
    const sliderContainer = sliderNode.getElementsByClassName('slider-viewport')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('actionSlider: no sliderContainer found by class "slider-viewport"')
        return false;
    }
    const prevButtonContainer = sliderNode.getElementsByClassName('prev')[0];
    const nextButtonContainer = sliderNode.getElementsByClassName('next')[0];
    if (prevButtonContainer === undefined || !prevButtonContainer) {
        console.log('actionSlider: no prevButtonContainer found by class "prev"');
        return false;
    }
    if (nextButtonContainer === undefined || !nextButtonContainer) {
        console.log('actionSlider: no nextButtonContainer found by class "next"');
        return false;
    }
    const sliders = sliderContainer.getElementsByClassName('slide');

    $(sliderContainer).slick({
		slidesToShow: 3,
		slidesToScroll: 1,
        arrows: true,
        infinite: true,
        nextArrow: nextButtonContainer,
        prevArrow: prevButtonContainer,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
    	]
	});
}


document.addEventListener('DOMContentLoaded', function() {
    const actionSliderNodes = document.getElementsByClassName('action-slider');
    for(let i = 0; i < actionSliderNodes.length; i++) {
        let actionSliderNode = actionSliderNodes.item(i);
        const actionSliderObj = new actionSlider(actionSliderNode);
    }
});
