function centerSlider(sliderNode) {
    const sliderContainer = sliderNode.getElementsByClassName('slider-viewport')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('centerSlider: no sliderContainer found by class "slider-viewport"')
        return false;
    }
    const sliderNavs = sliderNode.getElementsByClassName('navs')[0];
    if (sliderNavs === undefined || !sliderNavs) {
        console.log('centerSlider: no sliderNavs found by class "navs"');
        return false;
    }
    const prevButtonContainer = sliderNode.getElementsByClassName('prev')[0];
    const nextButtonContainer = sliderNode.getElementsByClassName('next')[0];
    if (prevButtonContainer === undefined || !prevButtonContainer) {
        console.log('mainSlider: no prevButtonContainer found by class "prev"');
        return false;
    }
    if (nextButtonContainer === undefined || !nextButtonContainer) {
        console.log('mainSlider: no nextButtonContainer found by class "next"');
        return false;
    }
    const sliders = sliderContainer.getElementsByClassName('slide');

    $(sliderContainer).slick({
        centerMode: true,
        centerPadding: '0',
		slidesToShow: 1,
		slidesToScroll: 1,
        arrows: true,
        infinite: true,
        dots: false,
        nextArrow: nextButtonContainer,
        prevArrow: prevButtonContainer,
        appendDots: sliderNavs,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    adaptiveHeight: true
                }
            },
    	]
	});
}


document.addEventListener('DOMContentLoaded', function() {
    const centerSliderNodes = document.getElementsByClassName('center-slider');
    for(let i = 0; i < centerSliderNodes.length; i++) {
        let centerSliderNode = centerSliderNodes.item(i);
        const centerSliderObj = new centerSlider(centerSliderNode);
    }
});
