function coursesSeminarsSlider(sliderNode) {
    const sliderContainer = sliderNode.getElementsByClassName('slider-viewport')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('coursesSeminarsSlider: no sliderContainer found by class "slider-viewport"')
        return false;
    }
    const prevButtonContainer = sliderNode.getElementsByClassName('prev')[0];
    const nextButtonContainer = sliderNode.getElementsByClassName('next')[0];
    if (prevButtonContainer === undefined || !prevButtonContainer) {
        console.log('coursesSeminarsSlider: no prevButtonContainer found by class "prev"');
        return false;
    }
    if (nextButtonContainer === undefined || !nextButtonContainer) {
        console.log('coursesSeminarsSlider: no nextButtonContainer found by class "next"');
        return false;
    }
    const sliders = sliderContainer.getElementsByClassName('slide');

    $(sliderContainer).slick({
		slidesToShow: 1,
		slidesToScroll: 1,
        arrows: true,
        infinite: true,
        nextArrow: nextButtonContainer,
        prevArrow: prevButtonContainer,
        adaptiveHeight: true,
        responsive: [

    	]
	});
}


document.addEventListener('DOMContentLoaded', function() {
    const coursesSeminarsSliderNodes = document.getElementsByClassName('courses-seminars-slider');
    for(let i = 0; i < coursesSeminarsSliderNodes.length; i++) {
        let coursesSeminarsSliderNode = coursesSeminarsSliderNodes.item(i);
        const coursesSeminarsSliderObj = new coursesSeminarsSlider(coursesSeminarsSliderNode);
    }
});
