let commons = require('./utils/commons');
import customTimerJS from './includes/custom_timer.js';
import customTabsJS from './includes/custom_tabs.js';
import centerSliderJS from './includes/center_slider.js';
import specialistsSliderJS from './includes/specialists_slider.js';
import specialistsDropdownJS from './includes/specialists_dropdown.js';
import qaDropdownJS from './includes/qa_dropdown.js';
import pricesDropdownJS from './includes/prices_dropdown.js';
import certificatesSliderJS from './includes/certificates_slider.js';
import coursesSeminarsSliderJS from './includes/courses_seminars_slider.js';
import patientsReviewsSliderJS from './includes/patients_reviews_slider.js';
import photoVideoSliderJS from './includes/photo_video_slider.js';
import newsSliderJS from './includes/news_slider.js';
import actionSliderJS from './includes/action_slider.js';
import gallerySliderJS from './includes/gallery_slider.js';
import singleImageSliderJS from './includes/single_image_slider.js';
import videoHolderJS from './includes/video_holder.js';
import pricesSliderJS from './includes/prices_slider.js';
import indexStyles from '../sass/common.scss';
import priorities1Img from '../img/priorities-1.svg';
import priorities2Img from '../img/priorities-2.svg';
import priorities3Img from '../img/priorities-3.svg';
import priorities4Img from '../img/priorities-4.svg';
import priorities5Img from '../img/priorities-5.svg';
import priorities6Img from '../img/priorities-6.svg';
import priorities11Img from '../img/priorities-1-1.svg';
import priorities21Img from '../img/priorities-2-1.svg';
import priorities31Img from '../img/priorities-3-1.svg';
import priorities41Img from '../img/priorities-4-1.svg';
import priorities51Img from '../img/priorities-5-1.svg';
import priorities61Img from '../img/priorities-6-1.svg';
import research1Img from '../img/research-1.svg';
import research2Img from '../img/research-2.svg';
import research3Img from '../img/research-3.svg';
import research4Img from '../img/research-4.svg';
import research5Img from '../img/research-5.svg';
import research6Img from '../img/research-6.svg';
import research7Img from '../img/research-7.svg';
import research8Img from '../img/research-8.svg';
import research9Img from '../img/research-9.svg';
import galleryImage1Img from '../img/gallery-image-1.jpg';
import galleryImage2Img from '../img/gallery-image-2.jpg';
import advantages1Img from '../img/advantages-1.svg';
import advantages2Img from '../img/advantages-2.svg';
import advantages3Img from '../img/advantages-3.svg';
import advantages4Img from '../img/advantages-4.svg';
import advantages5Img from '../img/advantages-5.svg';
import advantages6Img from '../img/advantages-6.svg';
import map1Img from '../img/map-1.jpg';
import map2Img from '../img/map-2.jpg';
import mapPointImg from '../img/map-point.png';
import mapPoint2Img from '../img/map-point-2.png';
import contactsAddressImg from '../img/contacts-address.png';
import contactsClockImg from '../img/contacts-clock.png';
import contactsPhoneImg from '../img/contacts-phone.png';
import contactsPlanetImg from '../img/contacts-planet.png';
import contactsMailImg from '../img/contacts-mail.png';
import doubleShevronDownImg from '../img/double-shevron-down.png';
import specialist1Img  from '../img/specialist-1.jpg';
import specialist2Img  from '../img/specialist-2.jpg';
import specialist3Img  from '../img/specialist-3.jpg';
import specialist4Img  from '../img/specialist-4.jpg';
import specialist5Img  from '../img/specialist-5.jpg';
import specialist6Img  from '../img/specialist-6.jpg';
import specialist7Img  from '../img/specialist-7.jpg';
import cert1Img from '../img/cert-1.jpg';
import cert2Img from '../img/cert-2.jpg';
import cert3Img from '../img/cert-3.jpg';
import cert4Img from '../img/cert-4.jpg';
import image1Img from '../img/image-1.jpg';
import image2Img from '../img/image-2.jpg';
import playButtonImg from '../img/play-button.png';
import calendarIconImg from '../img/calendar-icon.png';
import newsList1Img from '../img/news-list-1.jpg';
import newsList2Img from '../img/news-list-2.jpg';
import newsList3Img from '../img/news-list-3.jpg';
import newsList4Img from '../img/news-list-4.jpg';
import partner1Img from '../img/partner-1.jpg';
import partner2Img from '../img/partner-2.jpg';
import partner3Img from '../img/partner-3.jpg';
import partner4Img from '../img/partner-4.jpg';
import partner5Img from '../img/partner-5.jpg';
import partner6Img from '../img/partner-6.jpg';
import partner7Img from '../img/partner-7.jpg';
import partner8Img from '../img/partner-8.jpg';
import partner9Img from '../img/partner-9.jpg';
import partner10Img from '../img/partner-10.jpg';
import partner11Img from '../img/partner-11.jpg';
import partner12Img from '../img/partner-12.jpg';
import partner13Img from '../img/partner-13.jpg';
import partner14Img from '../img/partner-14.jpg';
import partner15Img from '../img/partner-15.jpg';
import partner16Img from '../img/partner-16.jpg';
import qa1Img from '../img/qa-1.svg';
import qa2Img from '../img/qa-2.svg';
import qa3Img from '../img/qa-3.svg';
import qa4Img from '../img/qa-4.svg';
import qa5Img from '../img/qa-5.svg';
import doctorBlock1Img from '../img/doctor-block-1.jpg';
import photoVideo1Img from '../img/photo-video-1.jpg';
import photoVideo2Img from '../img/photo-video-2.jpg';
import photoVideo3Img from '../img/photo-video-3.jpg';
import photoVideo4Img from '../img/photo-video-4.jpg';
import photoVideo5Img from '../img/photo-video-5.jpg';
import after1Img from '../img/after-1.jpg';
import after2Img from '../img/after-2.jpg';
import before1Img from '../img/before-1.jpg';
import before2Img from '../img/before-2.jpg';
import workAdvantage1Img from '../img/work-advantage-1.svg';
import workAdvantage2Img from '../img/work-advantage-2.svg';
import workAdvantage3Img from '../img/work-advantage-3.svg';
import workAdvantage4Img from '../img/work-advantage-4.svg';
import detailNews1Img from '../img/detail-news-1.jpg';
import documentsIconImg from '../img/documents-icon.png';
import article1Img from '../img/article-1.jpg';
import article2Img from '../img/article-2.jpg';
import article3Img from '../img/article-3.jpg';
import article4Img from '../img/article-4.jpg';
import articleDetail1Img from '../img/article-detail-1.jpg';
import articleDetailGallery1Img from '../img/article-detail-gallery-1.jpg';
import articleDetailGallery2Img from '../img/article-detail-gallery-2.jpg';
import articleDetailGallery3Img from '../img/article-detail-gallery-3.jpg';
import articleDetailGallery4Img from '../img/article-detail-gallery-4.jpg';
import articleDetailGallery5Img from '../img/article-detail-gallery-5.jpg';
import questionAnswer1Img from '../img/question-answer-1.jpg';
import review1Img from '../img/review-1.jpg';
import review2Img from '../img/review-2.jpg';
import actionsList1Img from '../img/actions-list-1.jpg';
import actionsList2Img from '../img/actions-list-2.jpg';
import actionThumbsUpIconImg from '../img/action-thumbs-up-icon.png';
import action1Img from '../img/action-1.jpg';
import action2Img from '../img/action-2.jpg';
import action3Img from '../img/action-3.jpg';
import action4Img from '../img/action-4.jpg';
import action5Img from '../img/action-5.jpg';
import events1Img from '../img/events-1.jpg';
import events2Img from '../img/events-2.jpg';
import gallerySlider1Img from '../img/gallery-slider-1.jpg';
import equipment1Img from '../img/equipment-1.jpg';
import equipment2Img from '../img/equipment-2.jpg';
import equipment3Img from '../img/equipment-3.jpg';
import equipment4Img from '../img/equipment-4.jpg';
import equipment5Img from '../img/equipment-5.jpg';
import equipment6Img from '../img/equipment-6.jpg';
