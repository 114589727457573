var countdown = require('countdown');
let commons = require('../utils/commons');

function customTimerinit(timerNode) {
    // elements
    this.baseNode = timerNode;
    this.dateDate = null;
    const dateStr = this.baseNode.dataset.date;
    if (!dateStr || dateStr === undefined) {
        console.error('customTimerinit: date data attribute not found in ', this.baseNode);
        return false;
    }
    try {
        this.dateDate = Date.parse(dateStr);
    }
    catch (e) {
        console.error('customTimerinit: error while parsing', dateStr, e );
        return false;
    }
    if (!this.dateDate || this.dateDate === NaN || this.dateDate === undefined) {
        console.error('customTimerinit: error while parsing date ', dateStr, 'use folowing format "YYYY-MM-DDTHH:MM:SS", for example 2020-03-30T14:23:09');
        return false;
    }
    this.dayNode = this.baseNode.getElementsByClassName('days-count')[0];
    if (this.dayNode === undefined || !this.dayNode) {
        console.log('customTimerinit: no dayNode found by class "days-count" in ', this.baseNode);
        return false;
    }
    this.hourNode = this.baseNode.getElementsByClassName('hour-count')[0];
    if (this.hourNode === undefined || !this.hourNode) {
        console.log('customTimerinit: no dayNode found by class "hour-count" in ', this.baseNode);
        return false;
    }
    this.minutesNode = this.baseNode.getElementsByClassName('minutes-count')[0];
    if (this.minutesNode === undefined || !this.minutesNode) {
        console.log('customTimerinit: no dayNode found by class "minutes-count" in ', this.baseNode);
        return false;
    }
    //methods
    this.updateTimerData = function(days, hours, minutes) {
        this.dayNode.innerHTML = days;
        this.hourNode.innerHTML = hours;
        this.minutesNode.innerHTML = minutes;
    }
    this.updateInterval = function() {
        let countDownObj = countdown(new Date(), this.dateDate, countdown.DAYS|countdown.HOURS|countdown.MINUTES);
        this.updateTimerData(countDownObj.days, countDownObj.hours, countDownObj.minutes);
    }
    //events
    let timerId = setTimeout(function() {
        this.updateInterval();
    }.bind(this), 60000);
    //default
}

document.addEventListener('DOMContentLoaded', function(){
    const customTimerContainers = document.getElementsByClassName('custom-timer');
    for(let i = 0; i < customTimerContainers.length; i++) {
       let customTimerContainer = customTimerContainers.item(i);
       const timerObj = new customTimerinit(customTimerContainer);
       timerObj.updateInterval();
    }
});
